import React from "react";
import classes from "./Button.module.scss";
import $ from "jquery";

const Button = ({ children, login, red }) => {

  const handleClick = () => {
    setTimeout(() => {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#mainForm").offset().top - 150,
        },
        500
      );
    }, 100);
  };

  return (
    <>
      {login ?
        <div className={`${classes.button} ${red ? classes.red : ''}`}>
          <span>{children}</span>
        </div>
        :
        <div
          className={`${classes.button} ${red ? classes.red : ''}`}
          onClick={handleClick}
        >
          <span>{children}</span>
        </div>
      }
    </>
  );
};

export default Button;
