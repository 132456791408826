import React from 'react'
import classes from "./Banner.module.scss"
import Button from "../../GlobalComponents/Button/Button"
import { Trans, useTranslation } from 'react-i18next';
import image from '../../../assets/img/main/1/banner.png';

const Banner = () => {
    const { t } = useTranslation("");
    return (
        <div className={[classes.banner, ""].join(" ")}>
            <div className={[classes.bannerBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.bannerContent, ""].join(" ")}>
                        <p className={[classes.bannerOverTitle, "font-36"].join(" ")}>
                            Nexia Law
                        </p>
                        <h1 className={[classes.bannerTitle, "font-64"].join(" ")}>
                            <Trans>main_1_1</Trans>
                        </h1>
                        <p className={[classes.bannerText, "font-20"].join(" ")}>
                            <Trans>main_1_2</Trans>
                        </p>
                        <div className={[classes.bannerBtn, ""].join(" ")}>
                            <Button><Trans>btn_consultation</Trans></Button>
                        </div>
                    </div>
                </div>
                <img className={classes.image} src={image} alt='' />
            </div>
        </div>
    )
}

export default Banner