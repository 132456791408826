import React from "react";
import classes from './Footer.module.scss';
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    const { hostname } = document.location;
    return (
        <footer className={classes.footer}>
            <div className="container">
                <div className={classes.content}>
                    <div className={classes.head}>
                        <h2 className={`${classes.title} font-40`}>
                            {t('footer.title')}
                        </h2>
                        <p className={classes.text}>
                            {t('footer.text')}
                        </p>
                    </div>
                    <div className={classes.contacts}>
                        <a
                            className={classes.item}
                            href={`mailto:support@${hostname}`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19.6626 5C19.9321 5 20.1526 5.22051 20.1526 5.49005V5.55207C20.1526 5.82161 19.9703 6.16616 19.7475 6.31776L11.9772 12.0929C11.7544 12.2445 11.3896 12.2445 11.1667 12.093L3.40529 6.32642C3.18242 6.17482 3 5.83037 3 5.56083V5.49015C3 5.22061 3.22051 5.0001 3.49005 5.0001H19.6626V5Z" fill="#7A1212" />
                                <path d="M11.1766 13.7164C11.3941 13.8757 11.7499 13.8758 11.9675 13.7166L19.7572 8.01625C19.9748 7.85707 20.1526 7.94734 20.1526 8.21688V18.5098C20.1526 18.7793 19.9321 18.9999 19.6626 18.9999H3.49005C3.22051 18.9999 3 18.7793 3 18.5098V8.21688C3 7.94734 3.17789 7.85717 3.39535 8.01645L11.1766 13.7164Z" fill="#7A1212" />
                            </svg>
                            support@{hostname}
                        </a>
                        {/* <div
                            className={classes.item}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z" fill="#7A1212" />
                            </svg>
                            adress
                        </div> */}
                    </div>
                    <div className={classes.docs}>
                        <a 
                            href="/docs/TERMS_&_CONDITIONS.pdf" 
                            target="_blank"
                        >
                            {t('footer.docs.terms')}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
