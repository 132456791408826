import React from 'react'
import classes from "./If.module.scss"
import { useTranslation } from 'react-i18next'
import 'swiper/css';
import image from '../../../assets/img/main/6/image.png';

const cards = [
    {
        title: 'experiencing.card_01.title',
        text: 'experiencing.card_01.text',
    },
    {
        title: 'experiencing.card_02.title',
        text: 'experiencing.card_02.text',
    },
    {
        title: 'experiencing.card_03.title',
        text: 'experiencing.card_03.text',
    },
    {
        title: 'experiencing.card_04.title',
        text: 'experiencing.card_04.text',
    },
];

const If = () => {
    const { t } = useTranslation("");

    return (
        <div className={[classes.block, "mb"].join(" ")}>
            <div className="container">
                <div className={classes.body}>
                    <div className={classes.content}>
                        <h2 className={`${classes.title} font-24`}>
                            {t('experiencing.title')}
                        </h2>
                        <div className={classes.cards}>
                            {cards.map((card, index) =>
                                <div key={index} className={classes.card}>
                                    <h4 className={`${classes.subtitle} font-20`}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={classes.text}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <img className={classes.image} src={image} alt='' />
                </div>
            </div>
        </div>
    );
};

export default If