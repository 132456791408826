import React, { useState } from 'react';
import classes from "./Bottom.module.scss";
import Button from '../../GlobalComponents/Button/Button';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { Trans, useTranslation } from 'react-i18next';

const Bottom = () => {
    const { t } = useTranslation("");
    const [check, setCheck] = useState(false);
    const [checkValid, setCheckValid] = useState(true);
    const [name, setName] = useState("");
    const [nameValid, setNameValid] = useState(true);
    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);
    const navigate = useNavigate();

    async function send() {
        const messageData = {
            siteName: window.location.hostname,
            name,
            phone,
        };
        try {
            const result = await axios.post('https://civbt.xyz/api/bot/dTRGJWLLMb', messageData);
            if (result.status === 200) {
                navigate("/thankyou")
                return true;
            } else {
                alert('Something went wrong. Try again');
                return false;
            }
        } catch (error) {
            if (error.response.data.status === 403) {
                alert(error.response.data.message);

                return;
            }
            alert(error.message);
        }
    };

    function valid() {
        let letsGo = true
        if (!check) {
            setCheckValid(false)
            letsGo = false
        } else {
            setCheckValid(true)
        }
        if (name.length < 2) {
            setNameValid(false)
            letsGo = false
        } else {
            setNameValid(true)
        }
        if (phone.length < 2) {
            setPhoneValid(false)
            letsGo = false
        } else {
            setPhoneValid(true)
        }
        return letsGo;
    };

    return (
        <div className={[classes.block, "mt"].join(" ")} id='mainForm'>
            <div className={[classes.blockBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.blockRow, ""].join(" ")}>
                        <div className={[classes.blockRowLeft, ""].join(" ")}>
                            <h2 className={`${classes.title} font-40`}>
                                {t('main_8_left_title')}
                            </h2>
                            <p className={[classes.blockRowLeftText, "font-20"].join(" ")}>
                                <Trans>main_8_left_1</Trans>
                            </p>
                            <div className={[classes.blockRowLeftForm, ""].join(" ")}>
                                <div className={nameValid ? [classes.blockRowLeftFormInput, ""].join(" ") : [classes.blockRowLeftFormInput, classes.error, ""].join(" ")}>
                                    <input type="text" placeholder={t("main_8_left_2")} className='font-16' value={name} onChange={(e) => {
                                        setName(e.target.value)
                                        valid()
                                    }} />
                                </div>
                                <div className={phoneValid ? [classes.blockRowLeftFormInput, ""].join(" ") : [classes.blockRowLeftFormInput, classes.error, ""].join(" ")}>
                                    <input type="text" placeholder={t("main_8_left_3")} className='font-16' value={phone} onChange={(e) => {
                                        setPhone(e.target.value)
                                        valid()
                                    }} />
                                </div>
                                <div
                                    onClick={() => {
                                        setCheck(!check)
                                        valid()
                                    }}
                                    className={check ? [classes.blockRowLeftFormCheckout, classes.active, "font-14"].join(" ") : checkValid ? [classes.blockRowLeftFormCheckout, "font-14"].join(" ") : [classes.blockRowLeftFormCheckout, classes.error, "font-14"].join(" ")}
                                >
                                    <span></span>
                                    <Trans>main_8_left_4</Trans>
                                </div>
                                <div className={[classes.blockRowLeftFormBtn, ""].join(" ")}
                                    onClick={() => {
                                        let res = valid()
                                        if (res) {
                                            send()
                                        } else {
                                            console.log("error")
                                        }
                                    }}
                                >
                                    <Button login={"true"}><Trans>btn_submit</Trans></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bottom;
