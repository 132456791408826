import React from 'react'
import classes from "./Approach.module.scss"
import img1 from "../../../assets/img/main/4/1.png"
import { Trans, useTranslation } from 'react-i18next'

const Approach = () => {
    const { t } = useTranslation("");
    return (
        <div className={[classes.approach, "mb"].join(" ")}>
            <div className={[classes.approachBody, ""].join(" ")}>
                <div className="container">
                    <div className={classes.body}>
                        <div className={[classes.approachContent, ""].join(" ")}>
                            <h2 className={[classes.approachContentTitle, "font-40"].join(" ")}>
                                <Trans>main_4_1</Trans>
                            </h2>
                            <p className={[classes.approachContentText, "font-14"].join(" ")}>
                                <Trans>main_4_2</Trans>
                            </p>
                            <ul className={[classes.approachContentList, ""].join(" ")}>
                                <li className={[classes.approachContentListItem, "font-16"].join(" ")}>
                                    <Trans>main_4_3</Trans>
                                </li>
                                <li className={[classes.approachContentListItem, "font-16"].join(" ")}>
                                    <Trans>main_4_4</Trans>
                                </li>
                                <li className={[classes.approachContentListItem, "font-16"].join(" ")}>
                                    <Trans>main_4_5</Trans>
                                </li>
                                <li className={[classes.approachContentListItem, "font-16"].join(" ")}>
                                    <Trans>main_4_6</Trans>
                                </li>
                            </ul>
                        </div>
                        <img className={classes.image} src={img1} alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Approach