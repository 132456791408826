import React from 'react';
import classes from "./ThankPage.module.scss";
import Header from '../../components/GlobalComponents/Header/Header';
import Thank from '../../components/ThankyouComponents/Thank/Thank';

const ThankPage = () => {
    return (
        <div>
            <div className={classes.hide}>
                <Header />
            </div>
            <Thank />
        </div>
    );
};

export default ThankPage;
