import React from 'react'
import classes from "./Thank.module.scss"
import Button from '../../../components/GlobalComponents/Button/Button'
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'
const Thank = () => {
    const { t } = useTranslation("");
    const { lang } = useParams();
    return (
        <div className={[classes.thankpage, ""].join(" ")}>
            <div className={[classes.thankpageBody, ""].join(" ")}>
                <div className={[classes.thankpageContent, ""].join(" ")}>
                    <h2 className={[classes.thankpageContentTitle, "font-36"].join(" ")}>
                        <Trans>thankyou_1</Trans>
                    </h2>
                    <p className={[classes.thankpageContentText, "font-20"].join(" ")}>
                        <Trans>thankyou_2</Trans>
                    </p>
                    <Link to={`/${lang}/`} className={[classes.thankpageContentBtn, ""].join(" ")}>
                        <Button login={"true"}><Trans>thankyou_3</Trans></Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Thank