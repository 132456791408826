import React from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/HomePage/Banner/Banner";
import SubBanner from "../../components/HomePage/SubBanner/SubBanner";
import Why from "../../components/HomePage/Why/Why";
import Approach from "../../components/HomePage/Approach/Approach";
import Slider from "../../components/HomePage/Slider/Slider";
import If from "../../components/HomePage/If/If";
import Brokers from "../../components/HomePage/Brokers/Brokers";
import Bottom from "../../components/HomePage/Bottom/Bottom";
import Footer from "../../components/GlobalComponents/Footer/Footer";

const HomePage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <SubBanner />
      <Why />
      <Approach />
      <Slider />
      <If />
      <Brokers />
      <Bottom />
      <Footer />
    </div>
  );
};

export default HomePage;
